var e = document.getElementsByClassName("choixQuantite");
for (let i = 0; i<e.length; i++) {
     
     e[i].addEventListener("click", function() {
          var qt = e[i].options[e[i].selectedIndex].value;
          // console.log(qt);
          var id = e[i].options[e[i].selectedIndex].id;
          
          // console.log(document.getElementById("isConnected"));
          if (document.getElementById("isConnected").value != 1) {
               alert("Vous devez vous connecter pour ajouter ce produit au panier");
               window.location.href = "/login";
               return;
          }

          if (qt != "aucune") {
               alert("Le produit a bien été jouté au panier")

               $.ajax({
                    type: "POST",
                    async: false,
                    url: '/ajouteraupanier',
                    // dataType: "json",
                    cache: false,
                    data: {
                         "qt": qt,
                         "id" : id
                    },
                    success: function () {
                         e[i].options[e[i].selectedIndex].value = "aucune";
                         window.location.reload();
                    }
               });
          }
     
     });
}