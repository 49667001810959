/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

 // require jQuery normally
const $ = require('jquery');

// const limitCharTest = require("./limitFormCharacters.js");
// console.log(limitCharTest);

// create global $ and jQuery variables
global.$ = global.jQuery = $;

//bootstrap customisation (col xxl)
import '../css/custom_bootstrap.scss';

// any CSS you import will output into a single css file (app.css in this case)
import '../css/_global.scss';
import '../css/app.css';
import '../css/accueil.css';
import '../css/footer.css';
import '../css/general.scss';
import '../css/header.css';
import '../css/laboratoire.css';
import '../css/renvoi-contact.css';
import '../css/contact.scss';
import '../css/colis.scss';
import '../css/block-left.scss';
import '../css/produit_au_detail.css';
import '../css/_produitDetailColor.scss';
import '../css/admin.scss';
import '../css/moncompte.scss';
import '../css/panier.scss';
import '../css/pdf.scss';
import '../css/validationCommande.css';
import '../css/bonCommande.css';
import '../css/mesCommandes.css';
import '../css/pulseButton.scss';
import '../css/grosVolumes.scss'
import '../css/_colorsite.scss';
import '../css/mentions_legales.scss';
import '../css/produitsPanierOuCommande.scss';
import '../css/login.scss';
import '../css/validerCommande.scss';
import '../css/navbarSiteClient.scss';
import '../css/carousels.scss';

// Général
import '../css/pagination.scss';

// Admin
import '../css/admin/menuAdmin.css';
import '../css/admin/listeProduits.scss';
import '../css/admin/generalAdmin.scss';
import '../css/admin/selectionDuMoment.scss';

import './getCount.js';
import './shared/menu.js';
import './modal.js';
// import './limitFormCharacters.js';

// BUNDLES (réutilisable sur d'autres sites)
import '../../src/Bundles/quantity.js';
import '../../src/Bundles/form-validation.js';

//bug browse bootstrap
import '../../src/Bundles/bugBrowseBootstrapForm/form.scss';
import '../../src/Bundles/bugBrowseBootstrapForm/correctifForms.js';

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
// import $ from 'jquery';

// require('bootstrap');