var body = $("#body");
var modal = $("#modal");
var box = $(".box-pdf");
var close = $(".modal-close");
// var html = document.getElement(html);
var html = document.getElementsByTagName("html")[0];

modal.css("display", "none");
body.css("overflow", "auto");

// console.log($(window).width());

if ($(window).width() > 1200) {

    box.on("click", function () {

        var el = document.getElementsByClassName("getPdfUrl");
        console.log(el[0]);

        modal.css("display", "flex");
        body.css("overflow", "hidden");
        html.style.overflowY = "hidden";

        var pdfViewer = $('.pdfviewer');

        var pdfEmbed = $('.embed-pdf');

        // URL PDF
        if (el[0] != undefined) {
            pdfEmbed.attr('src', el[0].id + $(this).attr('data-pdfName'));
            console.log(el[0].id+$(this).attr('data-pdfName'));
            // NOM DU FICHIER 
            var pdfName = $("#pdfName");
            // console.log(pdfName);
            pdfName.html($(this).attr('data-pdfTitle'));
            // close.html("❌");
        }

        // DESCRIPTION FICHIER
        var pdfDesc = $('#pdfDesc');
        pdfDesc.html($(this).attr('data-pdfDesc'));

    })

    // LE BOUTON CLOSE NE MARCHE PAS AVEC LA VUE DEV RESPONSIVE DE CHROME
    // REDIMENSIONNER LA FENÊTRE

    close.on("click", function () {
        modal.css("display", "none");
        body.css("overflow", "auto");
        html.style.overflowY = "scroll";

    })
}
