export const limitChars = () => {

    var texteRougeAffiche = false;

    var texteAffiche = "Vous ne pouvez pas écrire plus de caractère sinon l'affichage sera perturbé";

    function limitAndShowMessage(limitNumber ,formId, textMessage) {

        $("#"+formId).keyup(function(event) {

            var childDescription = document.getElementById(formId);
            var text = childDescription.value;
            var container = document.createElement("div");
            container.setAttribute("id", "redContainer");
            var limit = limitNumber;
        
            if (text.length > limit) {
                $("#"+formId).val(text.substring(0, limit));
                if (texteRougeAffiche == false) {  
                    var texteRougeDescription = document.createTextNode(textMessage);
                    container.appendChild(texteRougeDescription)
                    container.style.color = "red";
                    if (formId == "selection_du_moment_offre") {
                        document.getElementById("showLimitChar").classList.remove("d-none");
                    } else {
                        childDescription.parentNode.insertBefore(container, childDescription);
                        texteRougeAffiche = true;
                    }
                }
            }
        
            if (text.length <= limit - 1) {
                if (formId == "selection_du_moment_offre") {
                    document.getElementById("showLimitChar").classList.add("d-none");
                }
                if (document.getElementById("redContainer") != null) {  
                    var elem = document.getElementById("redContainer");
                    elem.remove();
                    texteRougeAffiche = false;
                }
            }
        });  
    }

    // LIMITATION POUR CKEDITOR ET SELON LES PAGES ICI
    // "assets/ckeditor/config.js";

    //POUR L'OFFRE DE LA PAGE SELECTION DU MOMENT (REMISE SUR FOND NOIR)
    //LA LIMITATION SE FAIT DANS macros.html.twig -> offre

    limitAndShowMessage(27, 'produit_nom', texteAffiche);
    limitAndShowMessage(50, 'produit_description', texteAffiche);
    limitAndShowMessage(27, 'colis_nom', texteAffiche);
    limitAndShowMessage(67, 'phraseFondRouge', texteAffiche);
    limitAndShowMessage(15, 'selection_du_moment_offre', texteAffiche);

}
